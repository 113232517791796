import request from '../utils/request';

/**
 * 获取轮播图数据
 * @param query
 * @returns {AxiosPromise}
 */
export const getBanner = (list) => {
    return request({
        url: '/sys/banner/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 添加轮播图
 * @param query
 * @returns {AxiosPromise}
 */
export const insertBanner = (banner) => {
    return request({
        url: '/sys/banner/insertBanner',
        dataType: "json",
        method: 'POST',
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: JSON.stringify(banner),
    });
};

//修改轮播图
export const updateBanner = (banner) => {
    return request({
        url: '/sys/banner/update',
        dataType: "json",
        method: 'POST',
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: JSON.stringify(banner),
    });
};

/**
 * 修改轮播图状态
 * @param query
 * @returns {AxiosPromise}
 */
export const updateBannerState = (id,state) => {
    return request({
        url: '/sys/banner/updateBannerState',
        method: 'POST',
        params:{id,state}
    });
};

