<template>
  <div style="padding: 20px">
    <!--条件搜索-->
    <div class="handle-box">
      <el-button type="primary" class="mr10" @click="addModule = true">新增活动</el-button>
      <el-select placeholder="类型" style="width: 120px;margin-right: 10px" v-model="query.flag">
        <div v-if="sysStudentType==3">
          <el-option label="全部"></el-option>
          <el-option label="中招体育" value="1"></el-option>
          <el-option label="少年" value="2"></el-option>
          <el-option label="高考体育" value="4"></el-option>
        </div>

        <div v-if="sysStudentType!=3">
          <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
          <el-option v-else label="少年" value="2"></el-option>
          <el-option label="高考体育" value="4"></el-option>
        </div>
      </el-select>
      <el-select v-model="query.regionId" placeholder="城市" class="handle-select mr10" >
        <el-option v-for="(item,index) in regionList" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="query.type" placeholder="类型" clearable class="handle-select mr10">
        <el-option key="轮播图" label="轮播图" value="1"></el-option>
        <el-option key="轮播图" label="公众号" value="2"></el-option>
      </el-select>
      <el-input v-model="query.explain" clearable placeholder="请输入活动备注内容" class="handle-select mr10"></el-input>
      <el-button type="primary" @click="getData()">搜索</el-button>
      <el-button type="info">重置</el-button>
    </div>
    <!--table展示数据-->
    <el-table
        height="610px"
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        :row-style="{height:0+'px'}"
        :cell-style="{padding:0+'px'}"
    >
      <el-table-column
          width="210"
          show-overflow-tooltip="true"
          prop="explain"
          label="活动备注"
      ></el-table-column>
      <el-table-column
          width="210"
          show-overflow-tooltip="true"
          prop="describe"
          label="活动标题">
      </el-table-column>
      <el-table-column
          width="240"
          show-overflow-tooltip="true"
          prop="url"
          label="活动路径"
      ></el-table-column>
      <el-table-column
          width="110"
          label="所属城市">
        <template #default="scope">{{ scope.row.regionName  }}</template>
      </el-table-column>
      <el-table-column
          width="160"
          align="center"
          label="活动图片">
        <template  v-slot="scope">
          <el-image
              class="code"
              :src="scope.row.picture">
            <template #error>
              <div class="code">
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
          width="120"
          show-overflow-tooltip="true"
          label="类型">
        <template #default="scope"><el-tag>{{ scope.row.flagName}}</el-tag></template>
      </el-table-column>
      <el-table-column
          width="120"
          show-overflow-tooltip="true"
          label="类型">
        <template #default="scope">{{ scope.row.type==1? '轮播图':'公众号'}}</template>
      </el-table-column>
      <el-table-column
          width="200"
          show-overflow-tooltip="true"
          prop="createTime"
          label="创建时间">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          prop="sort"
          label="排序">
      </el-table-column>
      <el-table-column
          label="状态">
        <template #default="scope">
          <el-tooltip :content="scope.row.state==='开启'? '关闭后此活动将无法展示':'确定要开启吗'" placement="top">
            <el-switch
                @click="setState(scope.row)"
                v-model="scope.row.state"
                :active-value="'开启'"
                :inactive-value="'关闭'">
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center">
        <template #default="scope">
          <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
          >编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <!--添加轮播图-->
    <el-dialog title="添加活动" v-model="addModule" width="800px">
        <el-form style="margin-right: 50px" :model="insertForm" :rules="rules"  ref="insertForm" label-width="120px" class="demo-ruleForm">
          <el-row >
            <el-col :span="8">
              <el-form-item class="itemWih" label="活动备注" prop="explain">
                <el-input v-model="insertForm.explain"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="活动标题" prop="describe">
                <el-input v-model="insertForm.describe">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="活动链接" prop="url">
                <el-input v-model="insertForm.url">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="8">
              <el-form-item class="itemWih" label="排序" prop="sort">
                <el-input v-model="insertForm.sort" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="regionId" label="城市">
                <el-select v-model="insertForm.regionId" placeholder="请选择城市">
                  <el-option v-for="(item,index) in regionList" :key="index" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="type" label="轮播类型">
                <el-select v-model="insertForm.type">
                  <el-option key="轮播图" label="轮播图" :value="1"></el-option>
                  <el-option key="轮播图" label="公众号" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="type" label="类型">
                <el-select placeholder="类型" style="width: 120px;margin-right: 10px" v-model="insertForm.flag">
                  <div v-if="sysStudentType==3">
                    <el-option label="全部"></el-option>
                    <el-option label="中招体育" value="1"></el-option>
                    <el-option label="少年" value="2"></el-option>
                    <el-option label="高考体育" value="4"></el-option>
                  </div>

                  <div v-if="sysStudentType!=3">
                    <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                    <el-option v-else label="少年" value="2"></el-option>
                    <el-option label="高考体育" value="4"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="type" label="缩略图">
                <el-upload
                    class="upload"
                    :http-request="uploadHttp"
                    :before-upload="beforeAvatarUpload"
                    :class="{disUoloadSty:activityImg!=null ? true:false}"
                    :on-remove="handleRemove"
                    :limit="1"
                    list-type="picture-card">
                 <el-button icon="Plus" text />
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
				<span class="dialog-footer">
					<el-button @click="addModule=false">取 消</el-button>
					<el-button type="primary" @click="insertActivity('insertForm')">确 定</el-button>
				</span>
        </template>
      </el-dialog>
      <!-- 编辑轮播图 -->
      <el-dialog title="编辑活动" v-model="editModules" width="800px">
        <el-form style="margin-right: 50px" :model="insertForm" :rules="rules"  ref="insertForm" label-width="120px" class="demo-ruleForm">
          <el-row >
            <el-col :span="8">
              <el-form-item class="itemWih" label="活动备注" prop="explain">
                <el-input v-model="insertForm.explain"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="活动标题" prop="describe">
                <el-input v-model="insertForm.describe">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="活动链接" prop="url">
                <el-input v-model="insertForm.url">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="8">
              <el-form-item class="itemWih" label="排序" prop="sort">
                <el-input v-model="insertForm.sort" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="regionId" label="城市">
                <el-select v-model="insertForm.regionId" placeholder="请选择城市">
                  <el-option v-for="(item,index) in regionList" :key="index" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="type" label="轮播类型">
                <el-select v-model="insertForm.type">
                  <el-option key="轮播图" label="轮播图" :value="1"></el-option>
                  <el-option key="轮播图" label="公众号" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="type" label="类型">
                <el-select placeholder="类型" style="width: 120px;margin-right: 10px" v-model="insertForm.flag">
                  <div v-if="sysStudentType==3">
                    <el-option label="全部"></el-option>
                    <el-option label="中招体育" value="1"></el-option>
                    <el-option label="少年" value="2"></el-option>
                    <el-option label="高考体育" value="4"></el-option>
                  </div>

                  <div v-if="sysStudentType!=3">
                    <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                    <el-option v-else label="少年" value="2"></el-option>
                    <el-option label="高考体育" value="4"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="type" label="缩略图">
                <el-upload
                    class="upload"
                    :http-request="uploadHttps"
                    :before-upload="beforeAvatarUpload"
                    :class="{disUoloadSty:activityImg!=null ? true:false}"
                    :on-remove="handleRemove"
                    :limit="1"
                    list-type="picture-card">
                 <el-button icon="Plus" text />
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="type" label="详情图">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="Imgurl"
                  :initial-index="1"
                  fit="cover"
    />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
				<span class="dialog-footer">
					<el-button @click="editModules=false">取 消</el-button>
					<el-button type="primary" @click="eidt('insertForm')">确 定</el-button>
				</span>
        </template>

      </el-dialog>
  </div>
</template>


<script type="text/ecmascript-6">
import {getBanner,insertBanner,updateBannerState,updateBanner} from "@/api/banner";
import { uploadImage} from '@/api/helpManual'
import {selectAreaByExpress} from "@/api/area";
// import ossClient from '@/utils/aliyun.oss.client';
export default {
  name: "Banner",
  data() {
    return {
      Imgurl:'',
      sysStudentType:localStorage.getItem('studentType'),
      regionList:[],
      tableData:[],
      pageTotal:null,
      editModules:false,
      addModule:false,//添加活动弹出层
      activityImg: null,
      uploadConf: {
        region: 'oss-cn-beijing',
        accessKeyId: 'LTAI4G88H5Fc5mEjzXaQipfW',
        accessKeySecret: '0Z4k2fYL96OSVjyHM8BQTKoNZyvmDa',
        bucket: 'yanhuosports',
      },
      insertForm:{},
      rules: {
        url: [{required: true, message: '请输入路径', trigger: 'blur'},],
        regionId: [{required: true, message: '请选择城市', trigger: 'blur'},],
        type: [{required: true, message: '请选择类型', trigger: 'blur'},],
      },
      query: {
        regionId: null,
        type:null,
        explain:'',
        flag:null,
        pageIndex:1,
        pageSize:10
      }
    }
  },
  methods:{
    //编辑弹出
    handleEdit(index,row) {
      this.editModules = true
      this.insertForm = row
      this.Imgurl = row.picture
    },
    //编辑确定
    eidt(){ 
      console.log(this.insertForm);
      updateBanner(this.insertForm).then(res=>{ 
        if(res.code==200){ 
          this.getData();
        }
        // console.log(res);
      })
    },
    /**
     * 阿里云OSS  初始化
     */
    // async init () {
    //   const aliyun = {
    //     Region: 'oss-cn-beijing',
    //     AccessKeyId: 'LTAI4G88H5Fc5mEjzXaQipfW',
    //     AccessKeySecret: '0Z4k2fYL96OSVjyHM8BQTKoNZyvmDa',
    //     Bucket: 'yanhuosports',
    //   };
    //   const {Region, AccessKeyId, AccessKeySecret, Bucket} = aliyun && aliyun.data;
    //   this.uploadConf.region = Region;
    //   this.uploadConf.accessKeyId = AccessKeyId;
    //   this.uploadConf.accessKeySecret = AccessKeySecret;
    //   this.uploadConf.bucket = Bucket;
    // },
    /**
     * 删除图片
     */
    handleRemove() {
      this.activityImg=null;
    },
    /**
     * 保存图片
     */
    uploadHttp({ file }) {
      this.activityImg=file;
      console.log(file);
      const data = new FormData()
      let fileName = 'activity/'
      data.append(
        'multipartFile', file
      )
      data.append(
        'fileName', fileName
      )
      data.append(
        'flag', 'activity'
      )
      uploadImage(data).then(res=>{ 
        if(res.code==200){ 
          this.insertForm.picture = res.data
          this.insertForm.url = res.data
        }
      })
    },
    // 编辑上传图片
    uploadHttps(file){
      // console.log(file.file);
      const data = new FormData()
      let fileName = 'activity/'
      data.append(
        'multipartFile', file.file
      )
      data.append(
        'fileName', fileName
      )
      data.append(
        'flag', 'activity'
      )
      uploadImage(data).then(res=>{ 
        if(res.code==200){ 
          this.insertForm.picture = res.data
        }
      })
    },  

    /**
     * 上传阿里云OSS
     */
    // async insertOSS(){
    //     return await new Promise((resolve, reject) => {
    //           const fileName = 'activity/'+this.getCurrentTime();  //定义唯一的文件名
    //           ossClient(this.uploadConf).put(fileName, this.activityImg, {'ContentType': 'image/png'}).then(({res, name}) => {
    //             if (res && res.status == 200) {
    //               this.insertForm.picture='https://codeimg.yanhuotiyu.com/'+name;
    //               console.log("上传图片成功")
    //               resolve(name);
    //             }
    //           }).catch(() => {
    //             this.$message.error('上传图片失败！');
    //             reject();
    //           });
    //     });
    // },
    /**
     * 图片限制
     */
    beforeAvatarUpload (file) {
      const isJPG = file.name.split('.')[1] === 'jpg';
      const isPNG = file.name.split('.')[1] === 'png';
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      return (isJPG || isPNG);
    },
    /**
     * 添加小程序轮播图活动
     */
    async insertActivity(val){
      // if (this.activityImg!=null){
      //   await this.insertOSS();
      // }
      this.$refs[val].validate((valid) => {
          if (valid) {
            insertBanner(this.insertForm).then(val => {
              this.addModule=false;
              if(val.code==200 &&val.data==1){
                this.$message.success ('添加成功');
                this.getData();
              }else {
                this.$message.error('添加失败！');
              }
            })
          }
      })
    },
    /**
     * 改变轮播图状态
     */
    setState(val){
      updateBannerState(val.id,val.state==="关闭"?'关闭':'开启').then(val => {
        if(val.code==200 &&val.data==1){
          this.getData();
          this.$message.success ('添加成功');
        }else {
          this.$message.error('添加失败！');
        }
      })
    },
    /**
     * 切换页面
     */
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    /**
     * 获取轮播图数据
     */
    getData(){
      getBanner(this.query).then(val => {
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
        console.log(val)
      })
    },
    /**
     * 获取时间
     */
    getCurrentTime() {
      //获取当前时间并打印
      let _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      return _this.gettime = yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
    }
  },
  created() {
    this.getData()
    // 城市下拉数据
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })
  }
}
</script>

<style scoped>
.handle-box {
  display: flex;
  margin-bottom: 20px;
}
.handle-select {
  width: 160px;
}
.mr10 {
  margin-right: 10px;
}
.code {
  margin-top: 8px;
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #efefef;
}

/deep/ .el-upload--picture-card{
  width: 80px;
  height: 80px;
}
/deep/ .el-upload{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .avatar{
  width: 80px;
  height: 80px;
}
.disUoloadSty /deep/ .el-upload--picture-card{
  display:none;   /* 上传按钮隐藏 */
}
</style>